export const albums_data = {
   "bloom": {
      "name": "Bloom",
      "photos": [
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6958.jpg",
            "slug": "mg-6958-jpg",
            "src": "/static/_gallery/albums/bloom/1600x1066_mg-6958-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bloom/1024x682_mg-6958-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bloom/1600x1066_mg-6958-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bloom/499x333_mg-6958-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bloom/800x533_mg-6958-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bloom/499x333_mg-6958-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6964.jpg",
            "slug": "mg-6964-jpg",
            "src": "/static/_gallery/albums/bloom/1600x1066_mg-6964-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bloom/1024x682_mg-6964-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bloom/1600x1066_mg-6964-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bloom/499x333_mg-6964-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bloom/800x533_mg-6964-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bloom/499x333_mg-6964-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6966.jpg",
            "slug": "mg-6966-jpg",
            "src": "/static/_gallery/albums/bloom/1600x1066_mg-6966-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/bloom/1024x682_mg-6966-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/bloom/1600x1066_mg-6966-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/bloom/499x333_mg-6966-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/bloom/800x533_mg-6966-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/bloom/499x333_mg-6966-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "bloom",
      "src": "/static/_gallery/albums/bloom/499x333_mg-6958-jpg.jpg"
   },
   "blueberries": {
      "name": "Blueberries",
      "photos": [
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8501.jpg",
            "slug": "mg-8501-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8501-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8501-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8501-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8501-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8501-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8501-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8502.jpg",
            "slug": "mg-8502-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x2400_mg-8502-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x1536_mg-8502-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x2400_mg-8502-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/500x750_mg-8502-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x1200_mg-8502-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/500x750_mg-8502-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8503.jpg",
            "slug": "mg-8503-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8503-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8503-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8503-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8503-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8503-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8503-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8505.jpg",
            "slug": "mg-8505-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8505-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8505-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8505-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8505-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8505-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8505-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8506.jpg",
            "slug": "mg-8506-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8506-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8506-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8506-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8506-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8506-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8506-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8507.jpg",
            "slug": "mg-8507-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8507-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8507-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8507-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8507-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8507-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8507-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8509.jpg",
            "slug": "mg-8509-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x2400_mg-8509-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x1536_mg-8509-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x2400_mg-8509-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/500x750_mg-8509-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x1200_mg-8509-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/500x750_mg-8509-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8511.jpg",
            "slug": "mg-8511-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8511-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8511-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8511-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8511-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8511-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8511-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8512.jpg",
            "slug": "mg-8512-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8512-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8512-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8512-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8512-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8512-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8512-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8513.jpg",
            "slug": "mg-8513-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8513-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8513-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8513-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8513-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8513-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8513-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8516.jpg",
            "slug": "mg-8516-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8516-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8516-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8516-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8516-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8516-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8516-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8517.jpg",
            "slug": "mg-8517-jpg",
            "src": "/static/_gallery/albums/blueberries/1600x1066_mg-8517-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/blueberries/1024x682_mg-8517-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/blueberries/1600x1066_mg-8517-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/blueberries/499x333_mg-8517-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/blueberries/800x533_mg-8517-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/blueberries/499x333_mg-8517-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "blueberries",
      "src": "/static/_gallery/albums/blueberries/499x333_mg-8501-jpg.jpg"
   },
   "farmers": {
      "name": "Farmers",
      "photos": [
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8410.jpg",
            "slug": "mg-8410-jpg",
            "src": "/static/_gallery/albums/farmers/1600x900_mg-8410-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/farmers/1024x576_mg-8410-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/farmers/1600x900_mg-8410-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/farmers/499x281_mg-8410-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/farmers/800x450_mg-8410-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/farmers/499x281_mg-8410-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "meredithatthepicnictable.jpg",
            "slug": "meredithatthepicnictable-jpg",
            "src": "/static/_gallery/albums/farmers/1600x1066_meredithatthepicnictable-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/farmers/1024x682_meredithatthepicnictable-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/farmers/1600x1066_meredithatthepicnictable-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/farmers/499x333_meredithatthepicnictable-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/farmers/800x533_meredithatthepicnictable-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/farmers/499x333_meredithatthepicnictable-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "meredithwithhat.jpg",
            "slug": "meredithwithhat-jpg",
            "src": "/static/_gallery/albums/farmers/1600x2400_meredithwithhat-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/farmers/1024x1536_meredithwithhat-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/farmers/1600x2400_meredithwithhat-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/farmers/500x750_meredithwithhat-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/farmers/800x1200_meredithwithhat-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/farmers/500x750_meredithwithhat-jpg.jpg",
            "width": 3648
         }
      ],
      "slug": "farmers",
      "src": "/static/_gallery/albums/farmers/499x281_mg-8410-jpg.jpg"
   },
   "flopsy": {
      "name": "Flopsy",
      "photos": [
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0298.jpg",
            "slug": "mg-0298-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0298-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0298-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0298-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0298-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0298-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0298-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0303.jpg",
            "slug": "mg-0303-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0303-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0303-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0303-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0303-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0303-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0303-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0306.jpg",
            "slug": "mg-0306-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0306-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0312.jpg",
            "slug": "mg-0312-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0312-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0312-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0312-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0312-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0312-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0312-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0314.jpg",
            "slug": "mg-0314-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0314-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0314-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0314-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0314-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0314-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0314-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0316.jpg",
            "slug": "mg-0316-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0316-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0316-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0316-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0316-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0316-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0316-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0320.jpg",
            "slug": "mg-0320-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0320-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0320-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0320-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0320-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0320-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0320-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0324.jpg",
            "slug": "mg-0324-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0324-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0324-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0324-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0324-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0324-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0324-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0330.jpg",
            "slug": "mg-0330-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0330-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0330-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0330-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0330-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0330-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0330-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0334.jpg",
            "slug": "mg-0334-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0334-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0334-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0334-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0334-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0334-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0334-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0337.jpg",
            "slug": "mg-0337-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0337-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0337-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0337-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0337-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0337-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0337-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0341.jpg",
            "slug": "mg-0341-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0341-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0341-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0341-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0341-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0341-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0341-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0343.jpg",
            "slug": "mg-0343-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0343-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0343-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0343-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0343-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0343-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0343-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0347.jpg",
            "slug": "mg-0347-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0347-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0347-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0347-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0347-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0347-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0347-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0350.jpg",
            "slug": "mg-0350-jpg",
            "src": "/static/_gallery/albums/flopsy/1600x2400_mg-0350-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flopsy/1024x1536_mg-0350-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flopsy/1600x2400_mg-0350-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flopsy/500x750_mg-0350-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flopsy/800x1200_mg-0350-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flopsy/500x750_mg-0350-jpg.jpg",
            "width": 3648
         }
      ],
      "slug": "flopsy",
      "src": "/static/_gallery/albums/flopsy/500x750_mg-0298-jpg.jpg"
   },
   "forest": {
      "name": "Forest",
      "photos": [
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0830.jpg",
            "slug": "mg-0830-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0830-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0830-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0830-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0830-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0830-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0830-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0838.jpg",
            "slug": "mg-0838-jpg",
            "src": "/static/_gallery/albums/forest/1600x900_mg-0838-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x576_mg-0838-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x900_mg-0838-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/499x281_mg-0838-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x450_mg-0838-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/499x281_mg-0838-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0840.jpg",
            "slug": "mg-0840-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0840-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0840-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0840-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0840-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0840-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0840-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0855.jpg",
            "slug": "mg-0855-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0855-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0855-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0855-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0855-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0855-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0855-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0856.jpg",
            "slug": "mg-0856-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0856-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0856-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0856-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0856-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0856-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0856-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0864.jpg",
            "slug": "mg-0864-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0864-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0864-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0864-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0864-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0864-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0864-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0874.jpg",
            "slug": "mg-0874-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0874-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0874-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0874-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0874-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0874-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0874-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0881.jpg",
            "slug": "mg-0881-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0881-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0881-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0881-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0881-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0881-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0881-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5146,
            "name": "_MG_0883.jpg",
            "slug": "mg-0883-jpg",
            "src": "/static/_gallery/albums/forest/1600x2257_mg-0883-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1444_mg-0883-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2257_mg-0883-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x705_mg-0883-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1128_mg-0883-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x705_mg-0883-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0886.jpg",
            "slug": "mg-0886-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0886-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0886-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0886-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0886-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0886-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0886-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 4658,
            "name": "_MG_0888.jpg",
            "slug": "mg-0888-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0888-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0888-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0888-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0888-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0888-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0888-jpg.jpg",
            "width": 3105
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0889.jpg",
            "slug": "mg-0889-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0889-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0889-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0889-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0889-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0889-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0889-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0894.jpg",
            "slug": "mg-0894-jpg",
            "src": "/static/_gallery/albums/forest/1600x1066_mg-0894-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x682_mg-0894-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x1066_mg-0894-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/499x333_mg-0894-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x533_mg-0894-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/499x333_mg-0894-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0936.jpg",
            "slug": "mg-0936-jpg",
            "src": "/static/_gallery/albums/forest/1600x1066_mg-0936-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x682_mg-0936-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x1066_mg-0936-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/499x333_mg-0936-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x533_mg-0936-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/499x333_mg-0936-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0940.jpg",
            "slug": "mg-0940-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0940-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0940-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0940-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0940-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0940-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0940-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0942.jpg",
            "slug": "mg-0942-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0942-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0942-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0942-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0942-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0942-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0942-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0943.jpg",
            "slug": "mg-0943-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0943-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0943-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0943-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0943-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0943-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0943-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0944.jpg",
            "slug": "mg-0944-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-0944-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-0944-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-0944-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-0944-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-0944-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-0944-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1003.jpg",
            "slug": "mg-1003-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1003-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1003-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1003-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1003-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1003-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1003-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1008.jpg",
            "slug": "mg-1008-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1008-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1008-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1008-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1008-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1008-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1008-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_1016.jpg",
            "slug": "mg-1016-jpg",
            "src": "/static/_gallery/albums/forest/1600x1066_mg-1016-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x682_mg-1016-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x1066_mg-1016-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/499x333_mg-1016-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x533_mg-1016-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/499x333_mg-1016-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3012,
            "name": "_MG_1018.jpg",
            "slug": "mg-1018-jpg",
            "src": "/static/_gallery/albums/forest/1600x1066_mg-1018-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x682_mg-1018-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x1066_mg-1018-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x333_mg-1018-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x533_mg-1018-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x333_mg-1018-jpg.jpg",
            "width": 4518
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1021.jpg",
            "slug": "mg-1021-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1021-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1023.jpg",
            "slug": "mg-1023-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1023-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1023-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1023-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1023-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1023-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1023-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_1028.jpg",
            "slug": "mg-1028-jpg",
            "src": "/static/_gallery/albums/forest/1600x900_mg-1028-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x576_mg-1028-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x900_mg-1028-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/499x281_mg-1028-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x450_mg-1028-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/499x281_mg-1028-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1104.jpg",
            "slug": "mg-1104-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1104-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1104-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1104-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1104-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1104-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1104-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_1130.jpg",
            "slug": "mg-1130-jpg",
            "src": "/static/_gallery/albums/forest/1600x1066_mg-1130-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x682_mg-1130-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x1066_mg-1130-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/499x333_mg-1130-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x533_mg-1130-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/499x333_mg-1130-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1133.jpg",
            "slug": "mg-1133-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1133-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1133-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1133-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1133-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1133-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1133-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_1152.jpg",
            "slug": "mg-1152-jpg",
            "src": "/static/_gallery/albums/forest/1600x1600_mg-1152-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1024_mg-1152-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x1600_mg-1152-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x500_mg-1152-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x800_mg-1152-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x500_mg-1152-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1154.jpg",
            "slug": "mg-1154-jpg",
            "src": "/static/_gallery/albums/forest/1600x2400_mg-1154-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/forest/1024x1536_mg-1154-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/forest/1600x2400_mg-1154-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/forest/500x750_mg-1154-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/forest/800x1200_mg-1154-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/forest/500x750_mg-1154-jpg.jpg",
            "width": 3648
         }
      ],
      "slug": "forest",
      "src": "/static/_gallery/albums/forest/500x750_mg-0830-jpg.jpg"
   },
   "goats": {
      "name": "Goats",
      "photos": [
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0389.jpg",
            "slug": "mg-0389-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_mg-0389-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_mg-0389-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_mg-0389-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_mg-0389-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_mg-0389-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_mg-0389-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_0392.jpg",
            "slug": "mg-0392-jpg",
            "src": "/static/_gallery/albums/goats/1600x1160_mg-0392-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x742_mg-0392-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x1160_mg-0392-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/500x362_mg-0392-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x580_mg-0392-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/500x362_mg-0392-jpg.jpg",
            "width": 4637
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_0393.jpg",
            "slug": "mg-0393-jpg",
            "src": "/static/_gallery/albums/goats/1600x1106_mg-0393-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x708_mg-0393-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x1106_mg-0393-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x345_mg-0393-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x553_mg-0393-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x345_mg-0393-jpg.jpg",
            "width": 4863
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0400.jpg",
            "slug": "mg-0400-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_mg-0400-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_mg-0400-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_mg-0400-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_mg-0400-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_mg-0400-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_mg-0400-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0403.jpg",
            "slug": "mg-0403-jpg",
            "src": "/static/_gallery/albums/goats/1600x2400_mg-0403-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x1536_mg-0403-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x2400_mg-0403-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/500x750_mg-0403-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x1200_mg-0403-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/500x750_mg-0403-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0410.jpg",
            "slug": "mg-0410-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_mg-0410-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_mg-0410-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_mg-0410-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_mg-0410-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_mg-0410-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_mg-0410-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 2565,
            "name": "_MG_0412.jpg",
            "slug": "mg-0412-jpg",
            "src": "/static/_gallery/albums/goats/1600x1660_mg-0412-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x1062_mg-0412-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x1660_mg-0412-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/500x519_mg-0412-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x830_mg-0412-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/500x519_mg-0412-jpg.jpg",
            "width": 2471
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6511.jpg",
            "slug": "mg-6511-jpg",
            "src": "/static/_gallery/albums/goats/1600x2844_mg-6511-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x1820_mg-6511-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x2844_mg-6511-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/500x888_mg-6511-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x1422_mg-6511-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/500x888_mg-6511-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6514.jpg",
            "slug": "mg-6514-jpg",
            "src": "/static/_gallery/albums/goats/1600x2844_mg-6514-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x1820_mg-6514-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x2844_mg-6514-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/500x888_mg-6514-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x1422_mg-6514-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/500x888_mg-6514-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6523.jpg",
            "slug": "mg-6523-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_mg-6523-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_mg-6523-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_mg-6523-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_mg-6523-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_mg-6523-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_mg-6523-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6525.jpg",
            "slug": "mg-6525-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_mg-6525-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_mg-6525-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_mg-6525-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_mg-6525-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_mg-6525-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_mg-6525-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6529.jpg",
            "slug": "mg-6529-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_mg-6529-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_mg-6529-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_mg-6529-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_mg-6529-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_mg-6529-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_mg-6529-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "justinwithbabygoat.jpg",
            "slug": "justinwithbabygoat-jpg",
            "src": "/static/_gallery/albums/goats/1600x900_justinwithbabygoat-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/goats/1024x576_justinwithbabygoat-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/goats/1600x900_justinwithbabygoat-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/goats/499x281_justinwithbabygoat-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/goats/800x450_justinwithbabygoat-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/goats/499x281_justinwithbabygoat-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "goats",
      "src": "/static/_gallery/albums/goats/499x281_mg-0389-jpg.jpg"
   },
   "misc": {
      "name": "Misc",
      "photos": [
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0806.jpg",
            "slug": "mg-0806-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-0806-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-0806-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-0806-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-0806-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-0806-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-0806-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0808.jpg",
            "slug": "mg-0808-jpg",
            "src": "/static/_gallery/albums/misc/1600x2400_mg-0808-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1536_mg-0808-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2400_mg-0808-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x750_mg-0808-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1200_mg-0808-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x750_mg-0808-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0810.jpg",
            "slug": "mg-0810-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-0810-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-0810-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-0810-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-0810-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-0810-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-0810-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3434,
            "name": "_MG_0812.jpg",
            "slug": "mg-0812-jpg",
            "src": "/static/_gallery/albums/misc/1600x1004_mg-0812-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x642_mg-0812-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1004_mg-0812-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x313_mg-0812-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x502_mg-0812-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x313_mg-0812-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0901.jpg",
            "slug": "mg-0901-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-0901-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-0901-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-0901-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-0901-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-0901-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-0901-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3411,
            "name": "_MG_6535.jpg",
            "slug": "mg-6535-jpg",
            "src": "/static/_gallery/albums/misc/1600x1438_mg-6535-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x920_mg-6535-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1438_mg-6535-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x449_mg-6535-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x719_mg-6535-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x449_mg-6535-jpg.jpg",
            "width": 3794
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6560.jpg",
            "slug": "mg-6560-jpg",
            "src": "/static/_gallery/albums/misc/1600x2844_mg-6560-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1820_mg-6560-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2844_mg-6560-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x888_mg-6560-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1422_mg-6560-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x888_mg-6560-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6570.jpg",
            "slug": "mg-6570-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6570-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6570-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6570-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6570-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6570-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6570-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6574.jpg",
            "slug": "mg-6574-jpg",
            "src": "/static/_gallery/albums/misc/1600x2844_mg-6574-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1820_mg-6574-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2844_mg-6574-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x888_mg-6574-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1422_mg-6574-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x888_mg-6574-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3389,
            "name": "_MG_6576.jpg",
            "slug": "mg-6576-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6576-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6576-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6576-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x333_mg-6576-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6576-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x333_mg-6576-jpg.jpg",
            "width": 5084
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6577.jpg",
            "slug": "mg-6577-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6577-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6577-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6577-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6577-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6577-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6577-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6578.jpg",
            "slug": "mg-6578-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6578-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6578-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6578-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6578-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6578-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6578-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6582.jpg",
            "slug": "mg-6582-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6582-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6582-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6582-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6582-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6582-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6582-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6584.jpg",
            "slug": "mg-6584-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6584-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6584-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6584-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6584-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6584-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6584-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6592.jpg",
            "slug": "mg-6592-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6592-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6592-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6592-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-6592-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6592-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-6592-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6593.jpg",
            "slug": "mg-6593-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6593-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6593-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6593-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-6593-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6593-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-6593-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6609.jpg",
            "slug": "mg-6609-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6609-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6609-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6609-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-6609-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6609-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-6609-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6611.jpg",
            "slug": "mg-6611-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6611-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6611-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6611-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-6611-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6611-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-6611-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6613.jpg",
            "slug": "mg-6613-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6613-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6613-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6613-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-6613-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6613-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-6613-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6639.jpg",
            "slug": "mg-6639-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-6639-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-6639-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-6639-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-6639-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-6639-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-6639-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6654.jpg",
            "slug": "mg-6654-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6654-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6654-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6654-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6654-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6654-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6654-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6656.jpg",
            "slug": "mg-6656-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6656-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6656-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6656-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6656-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6656-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6656-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6684.jpg",
            "slug": "mg-6684-jpg",
            "src": "/static/_gallery/albums/misc/1600x2644_mg-6684-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1692_mg-6684-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2644_mg-6684-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x826_mg-6684-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1322_mg-6684-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x826_mg-6684-jpg.jpg",
            "width": 3311
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6693.jpg",
            "slug": "mg-6693-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6693-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6693-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6693-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6693-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6693-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6693-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_6695.jpg",
            "slug": "mg-6695-jpg",
            "src": "/static/_gallery/albums/misc/1600x1638_mg-6695-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1048_mg-6695-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1638_mg-6695-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x512_mg-6695-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x819_mg-6695-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x512_mg-6695-jpg.jpg",
            "width": 3283
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6701.jpg",
            "slug": "mg-6701-jpg",
            "src": "/static/_gallery/albums/misc/1600x2844_mg-6701-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1820_mg-6701-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2844_mg-6701-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x888_mg-6701-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1422_mg-6701-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x888_mg-6701-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6703.jpg",
            "slug": "mg-6703-jpg",
            "src": "/static/_gallery/albums/misc/1600x2400_mg-6703-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1536_mg-6703-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2400_mg-6703-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x750_mg-6703-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1200_mg-6703-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x750_mg-6703-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6705.jpg",
            "slug": "mg-6705-jpg",
            "src": "/static/_gallery/albums/misc/1600x2400_mg-6705-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1536_mg-6705-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2400_mg-6705-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x750_mg-6705-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1200_mg-6705-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x750_mg-6705-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6707.jpg",
            "slug": "mg-6707-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6707-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6707-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6707-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6707-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6707-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6707-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6709.jpg",
            "slug": "mg-6709-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6709-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6709-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6709-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6709-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6709-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6709-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6712.jpg",
            "slug": "mg-6712-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6712-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6712-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6712-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6712-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6712-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6712-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6717.jpg",
            "slug": "mg-6717-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6717-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6717-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6717-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6717-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6717-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6717-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6718.jpg",
            "slug": "mg-6718-jpg",
            "src": "/static/_gallery/albums/misc/1600x2844_mg-6718-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1820_mg-6718-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2844_mg-6718-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x888_mg-6718-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1422_mg-6718-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x888_mg-6718-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6866.jpg",
            "slug": "mg-6866-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-6866-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-6866-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-6866-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-6866-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-6866-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-6866-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8360.jpg",
            "slug": "mg-8360-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-8360-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-8360-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-8360-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-8360-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-8360-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-8360-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8406.jpg",
            "slug": "mg-8406-jpg",
            "src": "/static/_gallery/albums/misc/1600x2844_mg-8406-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1820_mg-8406-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2844_mg-8406-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x888_mg-8406-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1422_mg-8406-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x888_mg-8406-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8416.jpg",
            "slug": "mg-8416-jpg",
            "src": "/static/_gallery/albums/misc/1600x2844_mg-8416-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1820_mg-8416-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2844_mg-8416-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x888_mg-8416-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1422_mg-8416-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x888_mg-8416-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8418.jpg",
            "slug": "mg-8418-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-8418-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-8418-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-8418-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-8418-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-8418-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-8418-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8480.jpg",
            "slug": "mg-8480-jpg",
            "src": "/static/_gallery/albums/misc/1600x2400_mg-8480-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1536_mg-8480-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2400_mg-8480-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/500x750_mg-8480-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1200_mg-8480-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/500x750_mg-8480-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5054,
            "name": "_MG_8481.jpg",
            "slug": "mg-8481-jpg",
            "src": "/static/_gallery/albums/misc/1600x2400_mg-8481-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x1536_mg-8481-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x2400_mg-8481-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x750_mg-8481-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x1200_mg-8481-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x750_mg-8481-jpg.jpg",
            "width": 3369
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8490.jpg",
            "slug": "mg-8490-jpg",
            "src": "/static/_gallery/albums/misc/1600x1066_mg-8490-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x682_mg-8490-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x1066_mg-8490-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x333_mg-8490-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x533_mg-8490-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x333_mg-8490-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8493.jpg",
            "slug": "mg-8493-jpg",
            "src": "/static/_gallery/albums/misc/1600x900_mg-8493-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/misc/1024x576_mg-8493-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/misc/1600x900_mg-8493-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/misc/499x281_mg-8493-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/misc/800x450_mg-8493-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/misc/499x281_mg-8493-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "misc",
      "src": "/static/_gallery/albums/misc/499x333_mg-0806-jpg.jpg"
   },
   "plants": {
      "name": "Plants",
      "photos": [
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0799.jpg",
            "slug": "mg-0799-jpg",
            "src": "/static/_gallery/albums/plants/1600x900_mg-0799-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x576_mg-0799-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x900_mg-0799-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/499x281_mg-0799-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x450_mg-0799-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/499x281_mg-0799-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0801.jpg",
            "slug": "mg-0801-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_mg-0801-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_mg-0801-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_mg-0801-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/499x333_mg-0801-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_mg-0801-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/499x333_mg-0801-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0802.jpg",
            "slug": "mg-0802-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_mg-0802-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_mg-0802-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_mg-0802-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/499x333_mg-0802-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_mg-0802-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/499x333_mg-0802-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0804.jpg",
            "slug": "mg-0804-jpg",
            "src": "/static/_gallery/albums/plants/1600x2400_mg-0804-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x1536_mg-0804-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x2400_mg-0804-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x750_mg-0804-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x1200_mg-0804-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x750_mg-0804-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0805.jpg",
            "slug": "mg-0805-jpg",
            "src": "/static/_gallery/albums/plants/1600x2844_mg-0805-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x1820_mg-0805-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x2844_mg-0805-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x888_mg-0805-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x1422_mg-0805-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x888_mg-0805-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8367.jpg",
            "slug": "mg-8367-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_mg-8367-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_mg-8367-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_mg-8367-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/499x333_mg-8367-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_mg-8367-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/499x333_mg-8367-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8368.jpg",
            "slug": "mg-8368-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_mg-8368-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_mg-8368-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_mg-8368-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/499x333_mg-8368-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_mg-8368-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/499x333_mg-8368-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "plants",
      "src": "/static/_gallery/albums/plants/499x281_mg-0799-jpg.jpg"
   },
   "september": {
      "name": "September",
      "photos": [
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0238.jpg",
            "slug": "mg-0238-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0238-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0238-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0238-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0238-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0238-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0238-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0249.jpg",
            "slug": "mg-0249-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0249-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0249-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0249-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0249-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0249-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0249-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0251.jpg",
            "slug": "mg-0251-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0251-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0251-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0251-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0251-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0251-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0251-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0256.jpg",
            "slug": "mg-0256-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0256-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0256-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0256-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0256-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0256-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0256-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0267.jpg",
            "slug": "mg-0267-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0267-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0267-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0267-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0267-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0267-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0267-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0278.jpg",
            "slug": "mg-0278-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0278-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0278-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0278-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0278-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0278-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0278-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0283.jpg",
            "slug": "mg-0283-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0283-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0283-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0283-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0283-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0283-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0283-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0284.jpg",
            "slug": "mg-0284-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0284-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0284-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0284-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0284-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0284-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0284-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0288.jpg",
            "slug": "mg-0288-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0288-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0288-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0288-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0288-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0288-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0288-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0293.jpg",
            "slug": "mg-0293-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0293-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0293-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0293-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0293-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0293-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0293-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0294.jpg",
            "slug": "mg-0294-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0294-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0294-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0294-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0294-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0294-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0294-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0353.jpg",
            "slug": "mg-0353-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0353-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0353-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0353-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0353-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0353-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0353-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0363.jpg",
            "slug": "mg-0363-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0363-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0363-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0363-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0363-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0363-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0363-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0373.jpg",
            "slug": "mg-0373-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0373-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0373-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0373-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0373-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0373-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0373-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0378.jpg",
            "slug": "mg-0378-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0378-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0378-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0378-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0378-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0378-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0378-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 4709,
            "name": "_MG_0382.jpg",
            "slug": "mg-0382-jpg",
            "src": "/static/_gallery/albums/september/1600x2065_mg-0382-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1321_mg-0382-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2065_mg-0382-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x645_mg-0382-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1032_mg-0382-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x645_mg-0382-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0384.jpg",
            "slug": "mg-0384-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0384-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0384-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0384-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0384-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0384-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0384-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0385.jpg",
            "slug": "mg-0385-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0385-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0385-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0385-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0385-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0385-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0385-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_0387.jpg",
            "slug": "mg-0387-jpg",
            "src": "/static/_gallery/albums/september/1600x983_mg-0387-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x629_mg-0387-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x983_mg-0387-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x307_mg-0387-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x491_mg-0387-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x307_mg-0387-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_0416.jpg",
            "slug": "mg-0416-jpg",
            "src": "/static/_gallery/albums/september/1600x983_mg-0416-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x629_mg-0416-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x983_mg-0416-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x307_mg-0416-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x491_mg-0416-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x307_mg-0416-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0425.jpg",
            "slug": "mg-0425-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0425-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0425-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0425-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0425-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0425-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0425-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0426.jpg",
            "slug": "mg-0426-jpg",
            "src": "/static/_gallery/albums/september/1600x900_mg-0426-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x576_mg-0426-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x900_mg-0426-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x281_mg-0426-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x450_mg-0426-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x281_mg-0426-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0615.jpg",
            "slug": "mg-0615-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0615-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0615-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0615-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0615-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0615-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0615-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0617.jpg",
            "slug": "mg-0617-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0617-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0617-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0617-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0617-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0617-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0617-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0619.jpg",
            "slug": "mg-0619-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0619-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0619-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0619-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0619-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0619-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0619-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0621.jpg",
            "slug": "mg-0621-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0621-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0621-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0621-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0621-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0621-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0621-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0709.jpg",
            "slug": "mg-0709-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0709-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0709-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0709-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0709-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0709-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0709-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0713.jpg",
            "slug": "mg-0713-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0713-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0713-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0713-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0713-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0713-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0713-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0717.jpg",
            "slug": "mg-0717-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0717-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0717-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0717-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0717-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0717-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0717-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0719.jpg",
            "slug": "mg-0719-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0719-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0719-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0719-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0719-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0719-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0719-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0723.jpg",
            "slug": "mg-0723-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0723-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0723-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0723-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0723-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0723-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0723-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0725.jpg",
            "slug": "mg-0725-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0725-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0725-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0725-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0725-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0725-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0725-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0730.jpg",
            "slug": "mg-0730-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0730-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0730-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0730-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0730-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0730-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0730-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0735.jpg",
            "slug": "mg-0735-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0735-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0735-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0735-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0735-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0735-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0735-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0737.jpg",
            "slug": "mg-0737-jpg",
            "src": "/static/_gallery/albums/september/1600x2400_mg-0737-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1536_mg-0737-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2400_mg-0737-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x750_mg-0737-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1200_mg-0737-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x750_mg-0737-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0751.jpg",
            "slug": "mg-0751-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_mg-0751-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_mg-0751-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_mg-0751-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_mg-0751-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_mg-0751-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_mg-0751-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 4577,
            "name": "_MG_0754.jpg",
            "slug": "mg-0754-jpg",
            "src": "/static/_gallery/albums/september/1600x2130_mg-0754-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1363_mg-0754-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2130_mg-0754-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x665_mg-0754-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1065_mg-0754-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x665_mg-0754-jpg.jpg",
            "width": 3437
         },
         {
            "faces": [],
            "height": 3648,
            "name": "gardeninseptember.jpg",
            "slug": "gardeninseptember-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_gardeninseptember-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_gardeninseptember-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_gardeninseptember-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_gardeninseptember-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_gardeninseptember-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_gardeninseptember-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "justin.jpg",
            "slug": "justin-jpg",
            "src": "/static/_gallery/albums/september/1600x2844_justin-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x1820_justin-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x2844_justin-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/500x888_justin-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x1422_justin-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/500x888_justin-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3648,
            "name": "milesonthetractor.jpg",
            "slug": "milesonthetractor-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_milesonthetractor-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_milesonthetractor-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_milesonthetractor-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_milesonthetractor-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_milesonthetractor-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_milesonthetractor-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "onions.jpg",
            "slug": "onions-jpg",
            "src": "/static/_gallery/albums/september/1600x1066_onions-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/september/1024x682_onions-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/september/1600x1066_onions-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/september/499x333_onions-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/september/800x533_onions-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/september/499x333_onions-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "september",
      "src": "/static/_gallery/albums/september/499x333_mg-0238-jpg.jpg"
   },
   "sorting": {
      "name": "Sorting",
      "photos": [
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8378.jpg",
            "slug": "mg-8378-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8378-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8378-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8378-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8378-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8378-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8378-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8380.jpg",
            "slug": "mg-8380-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8380-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8380-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8380-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8380-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8380-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8380-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8382.jpg",
            "slug": "mg-8382-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8382-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8382-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8382-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8382-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8382-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8382-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8383.jpg",
            "slug": "mg-8383-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8383-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8383-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8383-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8383-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8383-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8383-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8384.jpg",
            "slug": "mg-8384-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8384-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8384-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8384-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8384-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8384-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8384-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8385.jpg",
            "slug": "mg-8385-jpg",
            "src": "/static/_gallery/albums/sorting/1600x1066_mg-8385-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x682_mg-8385-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x1066_mg-8385-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/499x333_mg-8385-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x533_mg-8385-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/499x333_mg-8385-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8389.jpg",
            "slug": "mg-8389-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8389-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8389-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8389-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8389-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8389-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8389-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8393.jpg",
            "slug": "mg-8393-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2844_mg-8393-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1820_mg-8393-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2844_mg-8393-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x888_mg-8393-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1422_mg-8393-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x888_mg-8393-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8397.jpg",
            "slug": "mg-8397-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8397-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8397-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8397-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8397-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8397-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8397-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8423.jpg",
            "slug": "mg-8423-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2844_mg-8423-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1820_mg-8423-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2844_mg-8423-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x888_mg-8423-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1422_mg-8423-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x888_mg-8423-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8426.jpg",
            "slug": "mg-8426-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2844_mg-8426-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1820_mg-8426-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2844_mg-8426-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x888_mg-8426-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1422_mg-8426-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x888_mg-8426-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8430.jpg",
            "slug": "mg-8430-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2844_mg-8430-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1820_mg-8430-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2844_mg-8430-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x888_mg-8430-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1422_mg-8430-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x888_mg-8430-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8432.jpg",
            "slug": "mg-8432-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2844_mg-8432-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1820_mg-8432-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2844_mg-8432-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x888_mg-8432-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1422_mg-8432-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x888_mg-8432-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8434.jpg",
            "slug": "mg-8434-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2400_mg-8434-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1536_mg-8434-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2400_mg-8434-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x750_mg-8434-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1200_mg-8434-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x750_mg-8434-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8438.jpg",
            "slug": "mg-8438-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2844_mg-8438-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1820_mg-8438-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2844_mg-8438-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x888_mg-8438-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1422_mg-8438-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x888_mg-8438-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 4716,
            "name": "_MG_8440.jpg",
            "slug": "mg-8440-jpg",
            "src": "/static/_gallery/albums/sorting/1600x2269_mg-8440-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x1452_mg-8440-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x2269_mg-8440-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x709_mg-8440-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x1134_mg-8440-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x709_mg-8440-jpg.jpg",
            "width": 3325
         },
         {
            "faces": [],
            "height": 3009,
            "name": "_MG_8443.jpg",
            "slug": "mg-8443-jpg",
            "src": "/static/_gallery/albums/sorting/1600x899_mg-8443-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x575_mg-8443-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x899_mg-8443-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x281_mg-8443-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x449_mg-8443-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x281_mg-8443-jpg.jpg",
            "width": 5350
         },
         {
            "faces": [],
            "height": 2999,
            "name": "_MG_8449.jpg",
            "slug": "mg-8449-jpg",
            "src": "/static/_gallery/albums/sorting/1600x899_mg-8449-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/sorting/1024x575_mg-8449-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/sorting/1600x899_mg-8449-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/sorting/500x281_mg-8449-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/sorting/800x449_mg-8449-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/sorting/500x281_mg-8449-jpg.jpg",
            "width": 5332
         }
      ],
      "slug": "sorting",
      "src": "/static/_gallery/albums/sorting/500x750_mg-8378-jpg.jpg"
   },
   "storm": {
      "name": "Storm",
      "photos": [
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8567.jpg",
            "slug": "mg-8567-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8567-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8567-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8567-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8567-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8567-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8567-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8572.jpg",
            "slug": "mg-8572-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8572-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8572-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8572-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8572-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8572-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8572-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8576.jpg",
            "slug": "mg-8576-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8576-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8576-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8576-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8576-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8576-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8576-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8582.jpg",
            "slug": "mg-8582-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8582-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8582-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8582-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8582-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8582-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8582-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8584.jpg",
            "slug": "mg-8584-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8584-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8584-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8584-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8584-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8584-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8584-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8587.jpg",
            "slug": "mg-8587-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8587-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8587-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8587-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8587-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8587-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8587-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8588.jpg",
            "slug": "mg-8588-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8588-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8588-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8588-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8588-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8588-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8588-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8590.jpg",
            "slug": "mg-8590-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8590-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8590-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8590-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8590-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8590-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8590-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8592.jpg",
            "slug": "mg-8592-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8592-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8592-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8592-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8592-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8592-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8592-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8595.jpg",
            "slug": "mg-8595-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8595-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8595-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8595-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8595-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8595-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8595-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8596.jpg",
            "slug": "mg-8596-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8596-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8596-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8596-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8596-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8596-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8596-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8598.jpg",
            "slug": "mg-8598-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8598-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8598-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8598-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8598-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8598-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8598-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8599.jpg",
            "slug": "mg-8599-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8599-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8599-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8599-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8599-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8599-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8599-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8604.jpg",
            "slug": "mg-8604-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8604-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8604-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8604-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8604-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8604-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8604-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8609.jpg",
            "slug": "mg-8609-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8609-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8609-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8609-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8609-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8609-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8609-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8610.jpg",
            "slug": "mg-8610-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8610-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8610-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8610-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8610-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8610-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8610-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8612.jpg",
            "slug": "mg-8612-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8612-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8612-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8612-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8612-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8612-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8612-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8615.jpg",
            "slug": "mg-8615-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8615-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8615-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8615-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8615-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8615-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8615-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8616.jpg",
            "slug": "mg-8616-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8616-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8616-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8616-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8616-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8616-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8616-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8621.jpg",
            "slug": "mg-8621-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8621-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8621-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8621-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8621-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8621-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8621-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8623.jpg",
            "slug": "mg-8623-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8623-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8623-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8623-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8623-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8623-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8623-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8625.jpg",
            "slug": "mg-8625-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8625-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8625-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8625-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8625-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8625-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8625-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8632.jpg",
            "slug": "mg-8632-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8632-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8632-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8632-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8632-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8632-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8632-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8633.jpg",
            "slug": "mg-8633-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8633-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8633-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8633-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8633-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8633-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8633-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8635.jpg",
            "slug": "mg-8635-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8635-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8635-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8635-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8635-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8635-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8635-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8636.jpg",
            "slug": "mg-8636-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8636-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8636-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8636-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8636-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8636-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8636-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8637.jpg",
            "slug": "mg-8637-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8637-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8637-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8637-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8637-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8637-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8637-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8638.jpg",
            "slug": "mg-8638-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8638-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8638-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8638-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8638-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8638-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8638-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8640.jpg",
            "slug": "mg-8640-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8640-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8640-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8640-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8640-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8640-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8640-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8641.jpg",
            "slug": "mg-8641-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8641-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8641-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8641-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8641-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8641-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8641-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8642.jpg",
            "slug": "mg-8642-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8642-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8642-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8642-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8642-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8642-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8642-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8643.jpg",
            "slug": "mg-8643-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8643-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8643-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8643-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8643-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8643-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8643-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8645.jpg",
            "slug": "mg-8645-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8645-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8645-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8645-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8645-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8645-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8645-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8652.jpg",
            "slug": "mg-8652-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8652-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8652-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8652-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8652-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8652-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8652-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8653.jpg",
            "slug": "mg-8653-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8653-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8653-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8653-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8653-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8653-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8653-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8654.jpg",
            "slug": "mg-8654-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8654-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8654-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8654-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8654-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8654-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8654-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8655.jpg",
            "slug": "mg-8655-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8655-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8655-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8655-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8655-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8655-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8655-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8657.jpg",
            "slug": "mg-8657-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8657-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8657-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8657-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8657-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8657-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8657-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8658.jpg",
            "slug": "mg-8658-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8658-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8658-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8658-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8658-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8658-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8658-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8661.jpg",
            "slug": "mg-8661-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8661-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8661-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8661-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8661-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8661-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8661-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8662.jpg",
            "slug": "mg-8662-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8662-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8662-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8662-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8662-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8662-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8662-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8664.jpg",
            "slug": "mg-8664-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8664-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8664-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8664-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8664-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8664-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8664-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8666.jpg",
            "slug": "mg-8666-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8666-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8666-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8666-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8666-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8666-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8666-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8673.jpg",
            "slug": "mg-8673-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8673-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8673-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8673-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8673-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8673-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8673-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8676.jpg",
            "slug": "mg-8676-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8676-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8676-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8676-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8676-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8676-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8676-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8679.jpg",
            "slug": "mg-8679-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8679-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8679-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8679-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8679-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8679-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8679-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8681.jpg",
            "slug": "mg-8681-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8681-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8681-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8681-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8681-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8681-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8681-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_8682.jpg",
            "slug": "mg-8682-jpg",
            "src": "/static/_gallery/albums/storm/1600x983_mg-8682-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x629_mg-8682-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x983_mg-8682-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x307_mg-8682-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x491_mg-8682-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x307_mg-8682-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8688.jpg",
            "slug": "mg-8688-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8688-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8688-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8688-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8688-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8688-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8688-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8689.jpg",
            "slug": "mg-8689-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8689-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8689-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8689-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8689-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8689-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8689-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8690.jpg",
            "slug": "mg-8690-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8690-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8690-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8690-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8690-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8690-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8690-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8694.jpg",
            "slug": "mg-8694-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8694-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8694-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8694-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8694-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8694-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8694-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8695.jpg",
            "slug": "mg-8695-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8695-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8695-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8695-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8695-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8695-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8695-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8696.jpg",
            "slug": "mg-8696-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8696-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8696-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8696-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8696-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8696-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8696-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8707.jpg",
            "slug": "mg-8707-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8707-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8707-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8707-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8707-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8707-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8707-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8708.jpg",
            "slug": "mg-8708-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8708-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8708-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8708-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8708-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8708-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8708-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8709.jpg",
            "slug": "mg-8709-jpg",
            "src": "/static/_gallery/albums/storm/1600x2844_mg-8709-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1820_mg-8709-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2844_mg-8709-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x888_mg-8709-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1422_mg-8709-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x888_mg-8709-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8711.jpg",
            "slug": "mg-8711-jpg",
            "src": "/static/_gallery/albums/storm/1600x2667_mg-8711-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1707_mg-8711-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2667_mg-8711-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x833_mg-8711-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1333_mg-8711-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x833_mg-8711-jpg.jpg",
            "width": 3282
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8712.jpg",
            "slug": "mg-8712-jpg",
            "src": "/static/_gallery/albums/storm/1600x2400_mg-8712-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1536_mg-8712-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2400_mg-8712-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x750_mg-8712-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1200_mg-8712-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x750_mg-8712-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8713.jpg",
            "slug": "mg-8713-jpg",
            "src": "/static/_gallery/albums/storm/1600x2400_mg-8713-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1536_mg-8713-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2400_mg-8713-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x750_mg-8713-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1200_mg-8713-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x750_mg-8713-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_8714.jpg",
            "slug": "mg-8714-jpg",
            "src": "/static/_gallery/albums/storm/1600x900_mg-8714-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x576_mg-8714-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x900_mg-8714-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x281_mg-8714-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x450_mg-8714-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x281_mg-8714-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8715.jpg",
            "slug": "mg-8715-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8715-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8715-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8715-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8715-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8715-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8715-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8716.jpg",
            "slug": "mg-8716-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8716-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8716-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8716-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8716-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8716-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8716-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8717.jpg",
            "slug": "mg-8717-jpg",
            "src": "/static/_gallery/albums/storm/1600x2400_mg-8717-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1536_mg-8717-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2400_mg-8717-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x750_mg-8717-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1200_mg-8717-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x750_mg-8717-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8718.jpg",
            "slug": "mg-8718-jpg",
            "src": "/static/_gallery/albums/storm/1600x2400_mg-8718-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1536_mg-8718-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2400_mg-8718-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x750_mg-8718-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1200_mg-8718-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x750_mg-8718-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_8719.jpg",
            "slug": "mg-8719-jpg",
            "src": "/static/_gallery/albums/storm/1600x2400_mg-8719-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x1536_mg-8719-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x2400_mg-8719-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/500x750_mg-8719-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x1200_mg-8719-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/500x750_mg-8719-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8721.jpg",
            "slug": "mg-8721-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8721-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8721-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8721-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8721-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8721-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8721-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8723.jpg",
            "slug": "mg-8723-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8723-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8723-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8723-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8723-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8723-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8723-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8724.jpg",
            "slug": "mg-8724-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8724-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8724-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8724-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8724-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8724-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8724-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8726.jpg",
            "slug": "mg-8726-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8726-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8726-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8726-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8726-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8726-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8726-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8727.jpg",
            "slug": "mg-8727-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8727-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8727-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8727-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8727-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8727-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8727-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8733.jpg",
            "slug": "mg-8733-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8733-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8733-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8733-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8733-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8733-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8733-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8734.jpg",
            "slug": "mg-8734-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8734-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8734-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8734-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8734-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8734-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8734-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8735.jpg",
            "slug": "mg-8735-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_mg-8735-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_mg-8735-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_mg-8735-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_mg-8735-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_mg-8735-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_mg-8735-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "junieandsatch picking before the storm.jpg",
            "slug": "junieandsatch-picking-before-the-storm-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_junieandsatch-picking-before-the-storm-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_junieandsatch-picking-before-the-storm-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_junieandsatch-picking-before-the-storm-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_junieandsatch-picking-before-the-storm-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_junieandsatch-picking-before-the-storm-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_junieandsatch-picking-before-the-storm-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "picking before the storm.jpg",
            "slug": "picking-before-the-storm-jpg",
            "src": "/static/_gallery/albums/storm/1600x1066_picking-before-the-storm-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/storm/1024x682_picking-before-the-storm-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/storm/1600x1066_picking-before-the-storm-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/storm/499x333_picking-before-the-storm-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/storm/800x533_picking-before-the-storm-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/storm/499x333_picking-before-the-storm-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "storm",
      "src": "/static/_gallery/albums/storm/499x333_mg-8567-jpg.jpg"
   },
   "views": {
      "name": "Views",
      "photos": [
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0430.jpg",
            "slug": "mg-0430-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-0430-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-0430-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-0430-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-0430-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-0430-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-0430-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 2963,
            "name": "_MG_0432.jpg",
            "slug": "mg-0432-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-0432-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-0432-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-0432-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-0432-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-0432-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-0432-jpg.jpg",
            "width": 5267
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0434.jpg",
            "slug": "mg-0434-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0434-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0434-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0434-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0434-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0434-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0434-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0435.jpg",
            "slug": "mg-0435-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0435-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0435-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0435-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0435-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0435-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0435-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0436.jpg",
            "slug": "mg-0436-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0436-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0436-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0436-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0436-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0436-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0436-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0438.jpg",
            "slug": "mg-0438-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0438-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0438-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0438-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0438-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0438-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0438-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0440.jpg",
            "slug": "mg-0440-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0440-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0440-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0440-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0440-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0440-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0440-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0442.jpg",
            "slug": "mg-0442-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0442-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0442-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0442-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0442-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0442-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0442-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0739.jpg",
            "slug": "mg-0739-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0739-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0739-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0739-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0739-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0739-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0739-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0742.jpg",
            "slug": "mg-0742-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0742-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0742-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0742-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0742-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0742-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0742-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0743.jpg",
            "slug": "mg-0743-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0743-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0743-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0743-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0743-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0743-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0743-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0745.jpg",
            "slug": "mg-0745-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0745-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0745-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0745-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0745-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0745-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0745-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0747.jpg",
            "slug": "mg-0747-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0747-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0747-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0747-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0747-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0747-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0747-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0749.jpg",
            "slug": "mg-0749-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0749-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0749-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0749-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0749-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0749-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0749-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0759.jpg",
            "slug": "mg-0759-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0759-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0759-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0759-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0759-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0759-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0759-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0761.jpg",
            "slug": "mg-0761-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0761-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0761-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0761-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0761-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0761-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0761-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0763.jpg",
            "slug": "mg-0763-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0763-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0763-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0763-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0763-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0763-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0763-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0765.jpg",
            "slug": "mg-0765-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0765-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0765-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0765-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0765-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0765-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0765-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0767.jpg",
            "slug": "mg-0767-jpg",
            "src": "/static/_gallery/albums/views/1600x2400_mg-0767-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1536_mg-0767-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2400_mg-0767-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x750_mg-0767-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1200_mg-0767-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x750_mg-0767-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0778.jpg",
            "slug": "mg-0778-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-0778-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-0778-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-0778-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-0778-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-0778-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-0778-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0784.jpg",
            "slug": "mg-0784-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-0784-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-0784-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-0784-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-0784-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-0784-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-0784-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0891.jpg",
            "slug": "mg-0891-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0891-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0891-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0891-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0891-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0891-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0891-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0892.jpg",
            "slug": "mg-0892-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0892-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0892-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0892-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0892-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0892-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0892-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0895.jpg",
            "slug": "mg-0895-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0895-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0895-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0895-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0895-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0895-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0895-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0898.jpg",
            "slug": "mg-0898-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0898-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0898-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0898-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0898-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0898-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0898-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 2980,
            "name": "_MG_0909.jpg",
            "slug": "mg-0909-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0909-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0909-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0909-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x333_mg-0909-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0909-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x333_mg-0909-jpg.jpg",
            "width": 4470
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0917.jpg",
            "slug": "mg-0917-jpg",
            "src": "/static/_gallery/albums/views/1600x2400_mg-0917-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1536_mg-0917-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2400_mg-0917-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x750_mg-0917-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1200_mg-0917-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x750_mg-0917-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0923.jpg",
            "slug": "mg-0923-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0923-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0923-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0923-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0923-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0923-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0923-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0926.jpg",
            "slug": "mg-0926-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0926-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0926-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0926-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0926-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0926-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0926-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0928.jpg",
            "slug": "mg-0928-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0928-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0928-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0928-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0928-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0928-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0928-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0950.jpg",
            "slug": "mg-0950-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0950-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0950-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0950-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0950-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0950-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0950-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3577,
            "name": "_MG_0954.jpg",
            "slug": "mg-0954-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0954-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0954-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0954-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x333_mg-0954-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0954-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x333_mg-0954-jpg.jpg",
            "width": 5366
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0961.jpg",
            "slug": "mg-0961-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-0961-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-0961-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-0961-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-0961-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-0961-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-0961-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_1099.jpg",
            "slug": "mg-1099-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-1099-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-1099-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-1099-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-1099-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-1099-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-1099-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1101.jpg",
            "slug": "mg-1101-jpg",
            "src": "/static/_gallery/albums/views/1600x2400_mg-1101-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1536_mg-1101-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2400_mg-1101-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x750_mg-1101-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1200_mg-1101-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x750_mg-1101-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1108.jpg",
            "slug": "mg-1108-jpg",
            "src": "/static/_gallery/albums/views/1600x2400_mg-1108-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1536_mg-1108-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2400_mg-1108-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x750_mg-1108-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1200_mg-1108-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x750_mg-1108-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_1114.jpg",
            "slug": "mg-1114-jpg",
            "src": "/static/_gallery/albums/views/1600x2400_mg-1114-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1536_mg-1114-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2400_mg-1114-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x750_mg-1114-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1200_mg-1114-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x750_mg-1114-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6550.jpg",
            "slug": "mg-6550-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6550-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6550-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6550-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6550-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6550-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6550-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6552.jpg",
            "slug": "mg-6552-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6552-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6552-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6552-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6552-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6552-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6552-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6554.jpg",
            "slug": "mg-6554-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6554-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6554-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6554-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6554-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6554-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6554-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6557.jpg",
            "slug": "mg-6557-jpg",
            "src": "/static/_gallery/albums/views/1600x2844_mg-6557-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1820_mg-6557-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2844_mg-6557-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x888_mg-6557-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1422_mg-6557-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x888_mg-6557-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6558.jpg",
            "slug": "mg-6558-jpg",
            "src": "/static/_gallery/albums/views/1600x2844_mg-6558-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1820_mg-6558-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2844_mg-6558-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x888_mg-6558-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1422_mg-6558-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x888_mg-6558-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6562.jpg",
            "slug": "mg-6562-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6562-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6562-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6562-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6562-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6562-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6562-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6565.jpg",
            "slug": "mg-6565-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6565-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6565-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6565-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6565-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6565-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6565-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_6567.jpg",
            "slug": "mg-6567-jpg",
            "src": "/static/_gallery/albums/views/1600x983_mg-6567-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x629_mg-6567-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x983_mg-6567-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x307_mg-6567-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x491_mg-6567-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x307_mg-6567-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6586.jpg",
            "slug": "mg-6586-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6586-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6586-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6586-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6586-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6586-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6586-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6587.jpg",
            "slug": "mg-6587-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6587-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6587-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6587-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6587-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6587-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6587-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6600.jpg",
            "slug": "mg-6600-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6600-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6600-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6600-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6600-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6600-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6600-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6602.jpg",
            "slug": "mg-6602-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6602-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6602-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6602-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6602-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6602-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6602-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6605.jpg",
            "slug": "mg-6605-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6605-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6605-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6605-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6605-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6605-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6605-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6617.jpg",
            "slug": "mg-6617-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6617-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6617-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6617-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6617-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6617-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6617-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6619.jpg",
            "slug": "mg-6619-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6619-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6619-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6619-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6619-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6619-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6619-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6625.jpg",
            "slug": "mg-6625-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6625-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6625-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6625-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6625-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6625-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6625-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6631.jpg",
            "slug": "mg-6631-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6631-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6631-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6631-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6631-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6631-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6631-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6635.jpg",
            "slug": "mg-6635-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6635-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6635-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6635-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6635-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6635-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6635-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6643.jpg",
            "slug": "mg-6643-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6643-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6643-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6643-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6643-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6643-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6643-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6648.jpg",
            "slug": "mg-6648-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6648-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6648-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6648-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6648-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6648-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6648-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_6652.jpg",
            "slug": "mg-6652-jpg",
            "src": "/static/_gallery/albums/views/1600x2844_mg-6652-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x1820_mg-6652-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x2844_mg-6652-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/500x888_mg-6652-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x1422_mg-6652-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/500x888_mg-6652-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6662.jpg",
            "slug": "mg-6662-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6662-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6662-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6662-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6662-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6662-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6662-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6690.jpg",
            "slug": "mg-6690-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6690-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6690-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6690-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6690-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6690-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6690-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6914.jpg",
            "slug": "mg-6914-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6914-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6914-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6914-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6914-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6914-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6914-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6918.jpg",
            "slug": "mg-6918-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6918-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6918-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6918-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6918-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6918-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6918-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6920.jpg",
            "slug": "mg-6920-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6920-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6920-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6920-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6920-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6920-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6920-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_6926.jpg",
            "slug": "mg-6926-jpg",
            "src": "/static/_gallery/albums/views/1600x900_mg-6926-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x576_mg-6926-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x900_mg-6926-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x281_mg-6926-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x450_mg-6926-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x281_mg-6926-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6929.jpg",
            "slug": "mg-6929-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6929-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6929-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6929-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6929-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6929-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6929-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6930.jpg",
            "slug": "mg-6930-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6930-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6930-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6930-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6930-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6930-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6930-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6936.jpg",
            "slug": "mg-6936-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6936-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6936-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6936-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6936-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6936-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6936-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6938.jpg",
            "slug": "mg-6938-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6938-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6938-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6938-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6938-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6938-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6938-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6947.jpg",
            "slug": "mg-6947-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6947-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6947-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6947-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6947-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6947-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6947-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6968.jpg",
            "slug": "mg-6968-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6968-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6968-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6968-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6968-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6968-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6968-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6974.jpg",
            "slug": "mg-6974-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6974-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6974-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6974-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6974-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6974-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6974-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6976.jpg",
            "slug": "mg-6976-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6976-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6976-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6976-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6976-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6976-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6976-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6978.jpg",
            "slug": "mg-6978-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6978-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6978-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6978-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6978-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6978-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6978-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_6982.jpg",
            "slug": "mg-6982-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-6982-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-6982-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-6982-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-6982-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-6982-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-6982-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8500.jpg",
            "slug": "mg-8500-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-8500-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-8500-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-8500-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-8500-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-8500-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-8500-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8520.jpg",
            "slug": "mg-8520-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-8520-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-8520-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-8520-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-8520-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-8520-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-8520-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8521.jpg",
            "slug": "mg-8521-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-8521-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-8521-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-8521-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-8521-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-8521-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-8521-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_8523.jpg",
            "slug": "mg-8523-jpg",
            "src": "/static/_gallery/albums/views/1600x1066_mg-8523-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x682_mg-8523-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x1066_mg-8523-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x333_mg-8523-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x533_mg-8523-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x333_mg-8523-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 1554,
            "name": "westernview.jpg",
            "slug": "westernview-jpg",
            "src": "/static/_gallery/albums/views/1600x454_westernview-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/views/1024x290_westernview-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/views/1600x454_westernview-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/views/499x141_westernview-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/views/800x227_westernview-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/views/499x141_westernview-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "views",
      "src": "/static/_gallery/albums/views/499x281_mg-0430-jpg.jpg"
   },
   "weeding": {
      "name": "Weeding",
      "photos": [
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0147.jpg",
            "slug": "mg-0147-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0147-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0147-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0147-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0147-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0147-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0147-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0150.jpg",
            "slug": "mg-0150-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0150-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0150-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0150-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0150-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0150-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0150-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0156.jpg",
            "slug": "mg-0156-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0156-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0156-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0156-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0156-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0156-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0156-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0161.jpg",
            "slug": "mg-0161-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0161-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0161-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0161-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0161-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0161-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0161-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0167.jpg",
            "slug": "mg-0167-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0167-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0167-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0167-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0167-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0167-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0167-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0172.jpg",
            "slug": "mg-0172-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0172-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0172-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0172-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0172-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0172-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0172-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0173.jpg",
            "slug": "mg-0173-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0173-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0173-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0173-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0173-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0173-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0173-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0180.jpg",
            "slug": "mg-0180-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0180-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0180-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0180-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0180-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0180-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0180-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0186.jpg",
            "slug": "mg-0186-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0186-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0186-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0186-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0186-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0186-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0186-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0189.jpg",
            "slug": "mg-0189-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0189-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0189-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0189-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0189-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0189-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0189-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0193.jpg",
            "slug": "mg-0193-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0193-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0193-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0193-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0193-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0193-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0193-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0198.jpg",
            "slug": "mg-0198-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0198-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0198-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0198-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0198-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0198-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0198-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0202.jpg",
            "slug": "mg-0202-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0202-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0202-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0202-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0202-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0202-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0202-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0203.jpg",
            "slug": "mg-0203-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0203-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0203-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0203-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0203-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0203-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0203-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0206.jpg",
            "slug": "mg-0206-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0206-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0206-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0206-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0206-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0206-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0206-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0445.jpg",
            "slug": "mg-0445-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0445-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0445-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0445-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0445-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0445-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0445-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0446.jpg",
            "slug": "mg-0446-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0446-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0446-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0446-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0446-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0446-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0446-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0448.jpg",
            "slug": "mg-0448-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0448-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0448-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0448-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0448-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0448-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0448-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0453.jpg",
            "slug": "mg-0453-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0453-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0453-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0453-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0453-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0453-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0453-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0458.jpg",
            "slug": "mg-0458-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0458-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0458-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0458-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0458-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0458-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0458-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0467.jpg",
            "slug": "mg-0467-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0467-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0467-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0467-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0467-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0467-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0467-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0469.jpg",
            "slug": "mg-0469-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0469-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0469-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0469-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0469-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0469-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0469-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3813,
            "name": "_MG_0477.jpg",
            "slug": "mg-0477-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1982_mg-0477-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1268_mg-0477-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1982_mg-0477-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x619_mg-0477-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x991_mg-0477-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x619_mg-0477-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0480.jpg",
            "slug": "mg-0480-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0480-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0480-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0480-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0480-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0480-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0480-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0481.jpg",
            "slug": "mg-0481-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0481-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0481-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0481-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0481-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0481-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0481-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0483.jpg",
            "slug": "mg-0483-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0483-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0483-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0483-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0483-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0483-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0483-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0486.jpg",
            "slug": "mg-0486-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0486-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0486-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0486-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0486-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0486-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0486-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0488.jpg",
            "slug": "mg-0488-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0488-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0488-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0488-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0488-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0488-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0488-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0493.jpg",
            "slug": "mg-0493-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0493-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0493-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0493-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0493-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0493-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0493-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0499.jpg",
            "slug": "mg-0499-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0499-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0499-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0499-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0499-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0499-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0499-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0509.jpg",
            "slug": "mg-0509-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0509-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0509-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0509-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0509-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0509-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0509-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0512.jpg",
            "slug": "mg-0512-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0512-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0512-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0512-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0512-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0512-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0512-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0520.jpg",
            "slug": "mg-0520-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0520-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0520-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0520-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0520-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0520-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0520-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0525.jpg",
            "slug": "mg-0525-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0525-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0525-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0525-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0525-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0525-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0525-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0527.jpg",
            "slug": "mg-0527-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0527-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0527-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0527-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0527-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0527-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0527-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0529.jpg",
            "slug": "mg-0529-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0529-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0529-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0529-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0529-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0529-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0529-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0531.jpg",
            "slug": "mg-0531-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0531-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0531-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0531-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0531-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0531-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0531-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0535.jpg",
            "slug": "mg-0535-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0535-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0535-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0535-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0535-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0535-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0535-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0537.jpg",
            "slug": "mg-0537-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0537-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0537-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0537-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0537-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0537-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0537-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3078,
            "name": "_MG_0539.jpg",
            "slug": "mg-0539-jpg",
            "src": "/static/_gallery/albums/weeding/1600x900_mg-0539-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x576_mg-0539-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x900_mg-0539-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x281_mg-0539-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x450_mg-0539-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x281_mg-0539-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0544.jpg",
            "slug": "mg-0544-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0544-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0544-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0544-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0544-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0544-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0544-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0546.jpg",
            "slug": "mg-0546-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2844_mg-0546-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1820_mg-0546-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2844_mg-0546-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x888_mg-0546-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1422_mg-0546-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x888_mg-0546-jpg.jpg",
            "width": 3078
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0557.jpg",
            "slug": "mg-0557-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0557-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0557-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0557-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0557-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0557-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0557-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0558.jpg",
            "slug": "mg-0558-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0558-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0558-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0558-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0558-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0558-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0558-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0560.jpg",
            "slug": "mg-0560-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0560-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0560-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0560-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0560-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0560-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0560-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0563.jpg",
            "slug": "mg-0563-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0563-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0563-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0563-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0563-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0563-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0563-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0566.jpg",
            "slug": "mg-0566-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0566-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0566-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0566-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0566-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0566-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0566-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0568.jpg",
            "slug": "mg-0568-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0568-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0568-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0568-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0568-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0568-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0568-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0572.jpg",
            "slug": "mg-0572-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0572-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0572-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0572-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0572-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0572-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0572-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0574.jpg",
            "slug": "mg-0574-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0574-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0574-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0574-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0574-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0574-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0574-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0579.jpg",
            "slug": "mg-0579-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0579-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0579-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0579-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0579-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0579-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0579-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0582.jpg",
            "slug": "mg-0582-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0582-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0582-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0582-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0582-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0582-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0582-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0589.jpg",
            "slug": "mg-0589-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0589-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0589-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0589-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0589-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0589-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0589-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0590.jpg",
            "slug": "mg-0590-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0590-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0590-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0590-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0590-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0590-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0590-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0601.jpg",
            "slug": "mg-0601-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0601-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0601-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0601-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0601-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0601-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0601-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0602.jpg",
            "slug": "mg-0602-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0602-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0602-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0602-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0602-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0602-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0602-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0604.jpg",
            "slug": "mg-0604-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0604-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0604-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0604-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0604-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0604-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0604-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0608.jpg",
            "slug": "mg-0608-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0608-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0608-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0608-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0608-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0608-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0608-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0609.jpg",
            "slug": "mg-0609-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0609-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0609-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0609-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0609-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0609-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0609-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0623.jpg",
            "slug": "mg-0623-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0623-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0623-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0623-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0623-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0623-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0623-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0625.jpg",
            "slug": "mg-0625-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0625-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0625-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0625-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0625-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0625-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0625-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0636.jpg",
            "slug": "mg-0636-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0636-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0636-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0636-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0636-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0636-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0636-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 5472,
            "name": "_MG_0641.jpg",
            "slug": "mg-0641-jpg",
            "src": "/static/_gallery/albums/weeding/1600x2400_mg-0641-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x1536_mg-0641-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x2400_mg-0641-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/500x750_mg-0641-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x1200_mg-0641-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/500x750_mg-0641-jpg.jpg",
            "width": 3648
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0647.jpg",
            "slug": "mg-0647-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0647-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0647-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0647-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0647-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0647-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0647-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0649.jpg",
            "slug": "mg-0649-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0649-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0649-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0649-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0649-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0649-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0649-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0651.jpg",
            "slug": "mg-0651-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0651-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0651-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0651-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0651-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0651-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0651-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0654.jpg",
            "slug": "mg-0654-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0654-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0654-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0654-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0654-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0654-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0654-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0655.jpg",
            "slug": "mg-0655-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0655-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0655-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0655-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0655-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0655-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0655-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0657.jpg",
            "slug": "mg-0657-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0657-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0657-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0657-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0657-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0657-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0657-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0660.jpg",
            "slug": "mg-0660-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0660-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0660-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0660-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0660-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0660-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0660-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0661.jpg",
            "slug": "mg-0661-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0661-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0661-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0661-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0661-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0661-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0661-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0665.jpg",
            "slug": "mg-0665-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0665-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0665-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0665-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0665-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0665-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0665-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0667.jpg",
            "slug": "mg-0667-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0667-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0667-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0667-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0667-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0667-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0667-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0671.jpg",
            "slug": "mg-0671-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0671-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0671-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0671-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0671-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0671-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0671-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0673.jpg",
            "slug": "mg-0673-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0673-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0673-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0673-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0673-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0673-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0673-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0677.jpg",
            "slug": "mg-0677-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0677-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0677-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0677-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0677-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0677-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0677-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0679.jpg",
            "slug": "mg-0679-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0679-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0679-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0679-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0679-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0679-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0679-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0682.jpg",
            "slug": "mg-0682-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0682-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0682-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0682-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0682-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0682-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0682-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0684.jpg",
            "slug": "mg-0684-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0684-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0684-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0684-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0684-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0684-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0684-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0687.jpg",
            "slug": "mg-0687-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0687-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0687-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0687-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0687-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0687-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0687-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0688.jpg",
            "slug": "mg-0688-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0688-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0688-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0688-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0688-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0688-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0688-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0691.jpg",
            "slug": "mg-0691-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0691-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0691-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0691-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0691-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0691-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0691-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0693.jpg",
            "slug": "mg-0693-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0693-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0693-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0693-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0693-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0693-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0693-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0697.jpg",
            "slug": "mg-0697-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0697-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0697-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0697-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0697-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0697-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0697-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0699.jpg",
            "slug": "mg-0699-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0699-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0699-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0699-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0699-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0699-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0699-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0701.jpg",
            "slug": "mg-0701-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0701-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0701-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0701-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0701-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0701-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0701-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0703.jpg",
            "slug": "mg-0703-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0703-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0703-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0703-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0703-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0703-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0703-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0705.jpg",
            "slug": "mg-0705-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0705-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0705-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0705-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0705-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0705-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0705-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0707.jpg",
            "slug": "mg-0707-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0707-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0707-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0707-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0707-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0707-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0707-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_0788.jpg",
            "slug": "mg-0788-jpg",
            "src": "/static/_gallery/albums/weeding/1600x983_mg-0788-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x629_mg-0788-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x983_mg-0788-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x307_mg-0788-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x491_mg-0788-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x307_mg-0788-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0793.jpg",
            "slug": "mg-0793-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0793-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0793-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0793-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0793-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0793-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0793-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3363,
            "name": "_MG_0796.jpg",
            "slug": "mg-0796-jpg",
            "src": "/static/_gallery/albums/weeding/1600x983_mg-0796-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x629_mg-0796-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x983_mg-0796-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x307_mg-0796-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x491_mg-0796-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x307_mg-0796-jpg.jpg",
            "width": 5472
         },
         {
            "faces": [],
            "height": 3648,
            "name": "_MG_0798.jpg",
            "slug": "mg-0798-jpg",
            "src": "/static/_gallery/albums/weeding/1600x1066_mg-0798-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/weeding/1024x682_mg-0798-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/weeding/1600x1066_mg-0798-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/weeding/499x333_mg-0798-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/weeding/800x533_mg-0798-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/weeding/499x333_mg-0798-jpg.jpg",
            "width": 5472
         }
      ],
      "slug": "weeding",
      "src": "/static/_gallery/albums/weeding/499x333_mg-0147-jpg.jpg"
   }
};